import { useReducer, useCallback, useMemo } from 'react';

const TYPES = {
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_CONTENT: 'UPDATE_CONTENT'
};

const getCoreAction = (actionName, allActions) => {
  return allActions.find(coreAction => coreAction.name === actionName);
};

const setInitialAction = (action, allActions) => {
  if (!action.name) {
    return { name: null, content: null };
  }

  const coreAction = getCoreAction(action.name, allActions);

  return {
    name: action.name,
    content: coreAction.setInitialContent(action.content)
  };
};

const reducer = (action, { type, index, allActions, ...params }) => {
  switch (type) {
    // Edit single action
    case TYPES.UPDATE_NAME: {
      const coreAction = getCoreAction(params.name, allActions);

      return {
        ...action,
        name: params.name,
        content: coreAction.setInitialContent()
      };
    }
    case TYPES.UPDATE_CONTENT:
      return {
        ...action,
        content: { ...action.content, ...params.content }
      };

    default:
      throw new Error(`No event defined in useActionReducer for ${type}`);
  }
};

const defaultAction = {
  name: null,
  content: null
};

const useActionReducer = ({ initialAction = defaultAction, allActions }) => {
  const [action, dispatch] = useReducer(
    reducer,
    setInitialAction(initialAction, allActions)
  );

  const wrappedDispatch = useCallback(
    (params = {}) => {
      dispatch({ ...params, allActions });
    },
    [dispatch, allActions]
  );

  const valid = useMemo(() => {
    if (!action.name) {
      return false;
    }

    const coreAction = getCoreAction(action.name, allActions);

    return coreAction.valid(action.content);
  }, [action, allActions]);

  const form = { name: action.name, content: action.content };

  return { action, form, dispatch: wrappedDispatch, valid, TYPES };
};

export default useActionReducer;
