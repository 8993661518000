import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import {
  FormGroup,
  Label,
  TextInput,
  Flex,
  Checkbox,
  Button
} from '@ubisend/pulse-components';

import useComposer from '../../../hooks/useComposer';

const generateId = () => {
  return Math.random();
};

const Option = ({
  option,
  handleEditLabel,
  handleEditValue,
  handleDelete,
  handleOptionMove
}) => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'BUTTON',
    drop(item) {
      if (item.id === option.id) {
        return;
      }

      handleOptionMove(option.id, item.id);
    }
  });
  const [, drag] = useDrag({
    type: 'BUTTON',
    item: () => ({ id: option.id })
  });

  drag(buttonRef);
  drop(containerRef);

  return (
    <Flex mb xSpace ref={containerRef}>
      <Button variant="inline" icon="menuAlt4" ref={buttonRef} />
      <Flex col fat>
        <TextInput
          value={option.label}
          onChange={handleEditLabel(option)}
          placeholder="Dropdown label"
          aria-label="Fuzzy composer label"
        />
      </Flex>
      <Flex col fat>
        <TextInput
          value={option.value}
          onChange={handleEditValue(option)}
          placeholder="Message to send"
          aria-label="Fuzzy composer value"
        />
      </Flex>
      <Flex bottom>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete(option)}>
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  handleEditLabel: PropTypes.func.isRequired,
  handleEditValue: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOptionMove: PropTypes.func.isRequired
};

const FuzzyInput = () => {
  const composer = useComposer();

  const handleNewRow = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.concat({
          id: generateId(),
          label: '',
          value: ''
        })
      }
    });
  };

  const handleEditLabel = optionToEdit => event => {
    const label = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.map(option => {
          if (option.id === optionToEdit.id) {
            return { ...option, label };
          }
          return option;
        })
      }
    });
  };

  const handleEditValue = optionToEdit => event => {
    const value = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.map(option => {
          if (option.id === optionToEdit.id) {
            return { ...option, value };
          }
          return option;
        })
      }
    });
  };

  const handleOptionDelete = optionToDelete => () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.filter(
          option => option.id !== optionToDelete.id
        )
      }
    });
  };

  const handleOptionMove = (fromId, toId) => {
    const mutableItems = [...composer.content.items];
    const fromIndex = mutableItems.findIndex(item => item.id === fromId);
    const toIndex = mutableItems.findIndex(item => item.id === toId);

    mutableItems.splice(toIndex, 0, mutableItems.splice(fromIndex, 1)[0]);

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { items: mutableItems }
    });
  };

  const handlePlaceholderChange = event => {
    const placeholder = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { placeholder }
    });
  };

  const handleDisabledChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { disabled: !composer.content.disabled }
    });
  };

  const handleMultiChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { multi: !composer.content.multi }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="placeholder">Placeholder</Label>
        <TextInput
          id="placeholder"
          placeholder="Send a message"
          value={composer.content.placeholder}
          onChange={handlePlaceholderChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="options">Options</Label>
        {composer.content.items.map(option => (
          <Option
            key={option.id}
            option={option}
            handleEditLabel={handleEditLabel}
            handleEditValue={handleEditValue}
            handleDelete={handleOptionDelete}
            handleOptionMove={handleOptionMove}
          />
        ))}
        <Button variant="secondary" onClick={handleNewRow}>
          + Add option
        </Button>
      </FormGroup>
      <Flex center mb>
        <Checkbox
          checked={composer.content.disabled}
          onChange={handleDisabledChange}
          label="Deactivate composer"
        />
      </Flex>
      <Flex center>
        <Checkbox
          checked={composer.content.multi}
          onChange={handleMultiChange}
          label="Allow selection of multiple options"
        />
      </Flex>
    </>
  );
};

export default FuzzyInput;
