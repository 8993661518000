import React, { useMemo, useRef, useEffect } from 'react';
import { Switch, matchPath, useLocation, useHistory } from 'react-router-dom';

import { Flex, AppContainer } from '@ubisend/pulse-components';
import { SupportBot } from '@ubisend/pulse-support';
import { LanguagesProvider } from '@ubisend/pulse-hooks';
import { useAuth, Route, Auth } from '@ubisend/pulse-auth';

import routes from '../routes';
import RenderedRoute from './RenderedRoute';
import Sidebar from './Sidebar';

const defaultCanAccess = () => true;

const isAuthRoute = route => {
  return Boolean(route.auth);
};

const isCmsRoute = route => {
  return !route.public;
};

const RestorableScrollAppContainer = ({ children }) => {
  const scrollPositions = useRef({});
  const containerRef = useRef();

  const history = useHistory();
  const location = useLocation();

  const handleScroll = event => {
    const scrollTop = event.target.scrollTop;

    scrollPositions.current = {
      ...scrollPositions.current,
      [location.key]: scrollTop
    };
  };

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'POP') {
        if (!containerRef.current) {
          return;
        }

        const position = scrollPositions.current[location.key] || 0;

        containerRef.current.scrollTop = position;
      }
    });
  }, [history]);

  return (
    <AppContainer onScroll={handleScroll} ref={containerRef}>
      {children}
    </AppContainer>
  );
};

const Wrapper = ({ children }) => {
  const { authenticated, client } = useAuth();

  const location = useLocation();

  const route = useMemo(() => {
    return routes.find(route => {
      return matchPath(location.pathname, {
        path: route.path,
        exact: route.exact ?? true
      });
    });
  }, [location.pathname]);

  if (!route) {
    return children;
  }

  if (isAuthRoute(route)) {
    return <Auth>{children}</Auth>;
  }

  if (authenticated && isCmsRoute(route)) {
    return (
      <LanguagesProvider client={client}>
        <Flex>
          <Sidebar />
          <RestorableScrollAppContainer>
            {children}
            <SupportBot />
          </RestorableScrollAppContainer>
        </Flex>
      </LanguagesProvider>
    );
  }

  return children;
};

const Routes = () => {
  return (
    <Wrapper>
      <Switch>
        {routes.map((route, key) => {
          return (
            <Route
              public={route.public}
              exact={route.exact ?? true}
              key={key}
              path={route.path}
              canAccess={route.canAccess || defaultCanAccess}
              render={props => <RenderedRoute {...props} route={route} />}
            />
          );
        })}
      </Switch>
    </Wrapper>
  );
};

export default Routes;
