import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  useQueryClient,
  useQuery,
  useMutation,
  useCurrentTimeZone
} from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  Placeholder,
  PageWrapper,
  Heading3,
  InnerPanel,
  Button,
  TabButton,
  useNotification,
  Explainer,
  Tag
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import ActiveHours from './ActiveHours';
import MainSection from './MainSection';
import DetailsPanel from './DetailsPanel';
import AgentTickets from './AgentTickets';
import Activity from './Activity';
import AgentGroups from './AgentGroups';
import Agent from './Agent';
import { updateAgent } from '../api/index';

const statuses = {
  submitted: 'Reported',
  in_progress: 'In Progress',
  resolved: 'Resolved'
};

const getEvent = (status, previousStatus) => {
  if (!previousStatus) {
    return `"${status.ticket?.name || 'Ticket'}" was changed to ${
      statuses[status.status]
    }`;
  }

  return `"${status.ticket?.name || 'Ticket'}" was changed from ${
    statuses[previousStatus.status]
  } to ${statuses[status.status]}`;
};

const AgentDetails = ({ id, header }) => {
  const [tab, setTab] = useState('TICKETS');

  const { showSuccess } = useNotification();
  const timeZone = useCurrentTimeZone();

  const queryClient = useQueryClient();
  const query = useQuery(`tickets/ubidesk/agents/${id}`);
  const mutation = useMutation(updateAgent, {
    onSuccess: () => {
      queryClient.invalidateQueries(`tickets/ubidesk/agents/${id}`);
      showSuccess('Successfully updated agent details');
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <Flex>
      <MainSection>
        <PageWrapper
          header={header}
          actions={
            <Flex xSpace top>
              <PermissionFilter can="view tickets">
                <Button
                  as={Link}
                  variant="secondary"
                  to={`/tickets`}
                  icon="ticket">
                  Tickets
                </Button>
              </PermissionFilter>
              <PermissionFilter can="view agents">
                <Button
                  as={Link}
                  variant="secondary"
                  to={`/tickets/agents`}
                  icon="user">
                  Agents
                </Button>
              </PermissionFilter>
            </Flex>
          }>
          <Flex col ySpace>
            {query.isLoading && <Placeholder items={2} />}
            {!query.isLoading && (
              <>
                <Flex center xSpace>
                  <AnimateSharedLayout>
                    <TabButton
                      active={tab === 'TICKETS'}
                      onClick={() => setTab('TICKETS')}>
                      Tickets
                    </TabButton>
                    <TabButton
                      active={tab === 'ACTIVITY'}
                      onClick={() => setTab('ACTIVITY')}>
                      Activity
                    </TabButton>
                  </AnimateSharedLayout>
                </Flex>
                {tab === 'TICKETS' && <AgentTickets id={query.data.data.id} />}
                {tab === 'ACTIVITY' && (
                  <Activity
                    query={`tickets/ubidesk/agents/${id}/statuses`}
                    getEvent={getEvent}
                  />
                )}
              </>
            )}
          </Flex>
        </PageWrapper>
      </MainSection>
      <DetailsPanel divider header="Agent Information">
        {query.isLoading && <Placeholder />}
        {!query.isLoading && (
          <Flex col ySpace>
            <Flex col>
              <Flex mb>
                <Heading3>Agent details</Heading3>
              </Flex>
              <InnerPanel>
                <Flex mb>
                  <Explainer>
                    The agent name will appear in conversations when talking to
                    subscribers.
                  </Explainer>
                </Flex>
                <Agent
                  initialFirstName={query.data.data.first_name}
                  initialLastName={query.data.data.last_name}
                  initialSoundEnabled={query.data.data.sound_enabled}
                  handleSubmit={handleSubmit}
                  disabled={id !== 'me'}
                />
              </InnerPanel>
            </Flex>
            <Flex col>
              <Flex mb>
                <Heading3>Specialities</Heading3>
              </Flex>
              <InnerPanel>
                <AgentGroups
                  disabled={id !== 'me'}
                  loading={mutation.isLoading}
                  query={`tickets/ubidesk/agents/${id}/groups`}
                />
              </InnerPanel>
            </Flex>
            <Flex col>
              <Flex mb between center>
                <Heading3>Working hours</Heading3>
                {timeZone && <Tag>{timeZone}</Tag>}
              </Flex>
              <InnerPanel>
                <ActiveHours
                  disabled={id !== 'me'}
                  query={`tickets/ubidesk/agents/${id}/active-hours`}
                />
              </InnerPanel>
            </Flex>
          </Flex>
        )}
      </DetailsPanel>
    </Flex>
  );
};

AgentDetails.propTypes = {
  id: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired
};

export default AgentDetails;
export { statuses };
