import ActionInput from './ActionInput';
import { valid as validButton, setButton } from '../../../Buttons/Types/index';

const action = {
  id: 'action',
  name: 'Action',
  Input: ActionInput,
  setInitialContent: content => {
    const responses = content.responses || [];

    return {
      text: content.text || '',
      responses: responses.map(setButton)
    };
  },
  valid: content => {
    return (
      content.text.length > 0 &&
      content.responses.length > 0 &&
      content.responses.every(validButton)
    );
  },
  snippet: `{
    "type": "action",
    "content": {
      "text": "",
      "responses": [
        {"type": "postback", "label": "", "action": ""}
      ]
    }
}`
};

export default action;
