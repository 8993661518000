const feedback = {
  id: 'feedback',
  name: 'Feedback',
  Input: null,
  setInitialContent: () => {
    return {};
  },
  formatContent: () => {
    return {};
  },
  valid: () => true,
  snippet: `{
    "type": "feedback",
    "content": {} 
}`
};

export default feedback;
