import VideoInput from './VideoInput';

const video = {
  id: 'video',
  name: 'Video',
  Input: VideoInput,
  setInitialContent: content => {
    return {
      url: content.url || '',
      text: content.text || '',
      provider: content.provider || 'youtube',
      content_description: content.content_description || ''
    };
  },
  valid: content => {
    return content.url.length > 0;
  },
  snippet: `{
    "type": "video",
    "content": {
      "url": "",
      "text": "",
      "provider": "youtube",
      "content_description": ""
    }
}`
};

export default video;
