import types from './types';

const getType = (typeId = 'postback') => {
  return types.all.find(type => type.id === typeId);
};

const valid = ({ type: typeId, ...content }) => {
  const type = getType(typeId);

  return type.valid(content);
};

const setButton = ({ type: typeId, ...content }) => {
  const type = getType(typeId);

  return {
    type: typeId,
    ...type.setInitialContent(content)
  };
};

export default types;
export { getType, valid, setButton };
