import { useReducer } from 'react';
import dayjs from 'dayjs';

import { useAuth } from '@ubisend/pulse-auth';

import useConditionalTypes from './useConditionalTypes';
import {
  EVENT_TYPES,
  isEventType,
  hasNumberCondition,
  hasGroupCondition,
  hasTextCondition,
  showCondition
} from '../utils/index';

/**
 * Convert the API condition to one we use in state. Ran on
 * initial setting of state
 */
const setCondition = ({ type, condition }) => {
  if (!condition) {
    return '';
  }

  if (type === 'date-time-message-check') {
    return dayjs(condition).format('YYYY-MM-DD HH:mm:ss');
  }

  if (type === 'time-message-check') {
    return dayjs(condition).format('HH:mm:ss');
  }

  return condition;
};

/**
 * Convert the API conditional to one we use in state. Ran on
 * initial setting of state
 */
const setConditionals = ({ conditionals, match }, subjects) => {
  return {
    match,
    conditionals: conditionals.map(conditional => {
      // Convert the API structure to our own.
      if (conditional?.type?.name) {
        const subject = subjects.find(({ subject }) => {
          return subject.type === conditional.subject?.type;
        });

        const { toState } = subject || subjects[0];

        return {
          subject: toState(conditional),
          type: conditional.type.name,
          condition: setCondition({
            type: conditional.type.type,
            condition: conditional.condition
          }),
          flipped: Boolean(conditional.flipped),
          case_sensitive: Boolean(conditional.case_sensitive),
          target: conditional.subject?.target || ''
        };
      }

      return conditional;
    })
  };
};

/**
 * Migrate the value of the condition from one type to another if sharing
 * a similar type. For example if going from one text type to another,
 * copy over the condition string.
 */
const migrateCondition = ({ type, condition, newType, types }) => {
  const newCategory = types.find(({ name }) => name === newType).type;

  if (!showCondition(newCategory)) {
    return null;
  }

  const oldCategory = types.find(({ name }) => name === type)?.type || null;

  if (hasNumberCondition(newCategory)) {
    return hasNumberCondition(oldCategory) ? condition : '0';
  }

  if (hasGroupCondition(newCategory)) {
    return hasGroupCondition(oldCategory) ? condition : null;
  }

  if (hasTextCondition(newCategory) && condition) {
    return condition;
  }

  return '';
};

const reducer = (state, { type, ...params }) => {
  /**
   * Helper to update a single conditional, and only updating the given keys.
   */
  const updateConditional = callback => ({
    ...state,
    conditionals: state.conditionals.map((conditional, key) => {
      if (params.index !== key) {
        return conditional;
      }

      return { ...conditional, ...callback(conditional) };
    })
  });

  switch (type) {
    // Global settings
    case 'CHANGE_MATCH':
      return { ...state, match: params.newMatch };

    // Conditional values
    case 'CHANGE_SUBJECT':
      return updateConditional(() => {
        return {
          subject: params.newSubject,
          type: null,
          target: '',
          condition: null
        };
      });
    case 'CHANGE_TYPE':
      return updateConditional(conditional => {
        return {
          condition: migrateCondition({ ...conditional, ...params }),
          type: params.newType,
          subject: params.newSubject || conditional.subject
        };
      });
    case 'CHANGE_CONDITION':
      return updateConditional(() => ({ condition: params.newCondition }));
    case 'CHANGE_TARGET':
      return updateConditional(() => ({
        target: params.newTarget
      }));

    // Conditional settings
    case 'CHANGE_CASING':
      return updateConditional(conditional => ({
        case_sensitive: !conditional.case_sensitive
      }));
    case 'CHANGE_FLIPPED':
      return updateConditional(conditional => ({
        flipped: !conditional.flipped
      }));

    // Global conditional management
    case 'ADD_CONDITIONAL':
      return {
        ...state,
        conditionals: state.conditionals.concat({
          ...params.subjects[0].conditional
        })
      };
    case 'DELETE_CONDITIONAL':
      return {
        ...state,
        conditionals: state.conditionals.filter(
          (_, key) => key !== params.index
        )
      };

    default:
      throw new Error();
  }
};

const useConditionalReducer = ({
  subjects,
  conditionals = { match: 'any', conditionals: [subjects[0].conditional] }
} = {}) => {
  const { hasPermission } = useAuth();
  const [state, initialDispatch] = useReducer(
    reducer,
    setConditionals(conditionals, subjects)
  );
  const types = useConditionalTypes();

  const dispatch = (params = {}) => {
    return initialDispatch({ types, subjects, ...params });
  };

  const getSubject = type => {
    return subjects.find(({ subject }) => subject.type === type);
  };

  const handleSubjectChange = index => ({ value }) => {
    const subject = getSubject(value);

    if (subject) {
      dispatch({
        type: 'CHANGE_SUBJECT',
        newSubject: subject.subject,
        index
      });
    }

    // Is an event.
    else {
      dispatch({
        type: 'CHANGE_TYPE',
        newType: value,
        newSubject: { id: null, type: 'message' },
        index
      });
    }
  };

  const handleConditionalChange = index => ({ value }) => {
    dispatch({ type: 'CHANGE_TYPE', newType: value, index });
  };

  const handleConditionChange = index => newCondition => {
    dispatch({ type: 'CHANGE_CONDITION', newCondition, index });
  };

  const handleNewConditional = () => dispatch({ type: 'ADD_CONDITIONAL' });

  const handleConditionalDelete = index => () => {
    dispatch({ type: 'DELETE_CONDITIONAL', index });
  };

  const resetConditional = index => {
    dispatch({ type: 'CHANGE_TYPE', newType: null, index });
  };

  const handleMatchChange = ({ value }) => {
    dispatch({ type: 'CHANGE_MATCH', newMatch: value });
  };

  const handleTargetChange = index => event => {
    const value = event.target.value;

    dispatch({ type: 'CHANGE_TARGET', newTarget: value, index });
  };

  const handleCaseToggle = index => e => {
    e.stopPropagation();
    dispatch({ type: 'CHANGE_CASING', index });
  };

  const handleFlippedToggle = index => () => {
    dispatch({ type: 'CHANGE_FLIPPED', index });
  };

  const formattedSubjects = subjects
    .filter(({ name }) => name)
    .map(({ name, permission, subject }) => {
      return {
        label: name,
        value: subject.type,
        type: 'subject',
        isDisabled: permission ? !hasPermission(permission) : false
      };
    });

  const format = conditional => {
    const { toApi } = subjects.find(({ subject }) => {
      return subject.type === conditional.subject.type;
    });

    return {
      subject: toApi(conditional),
      type: conditional.type,
      condition: conditional.condition || null,
      flipped: conditional.flipped || false,
      case_sensitive: conditional.case_sensitive || false,
      target: conditional.target || ''
    };
  };

  /**
   * Get the value for the first dropdown in a conditional. This dropdown shows
   * every subject, as well as every event.
   * @param  options.type             The name of the conditional type (e.g. A message is sent)
   * @param  options.conditionalType  The type of conditional type (e.g. event);
   */
  const getSubjectValue = ({ type, conditionalType, subject }) => {
    if (isEventType(conditionalType)) {
      return { label: type, value: type, type: 'event' };
    }

    return formattedSubjects.find(({ value }) => value === subject.type);
  };

  const valid = state.conditionals.every(({ type }) => {
    return type;
  });

  const form = {
    ...state,
    conditionals: state.conditionals.map(format)
  };

  const events = types.filter(({ type }) => EVENT_TYPES.includes(type));

  return {
    ...state,
    // Updating state
    dispatch,
    handleConditionalChange,
    handleSubjectChange,
    handleConditionChange,
    handleNewConditional,
    handleConditionalDelete,
    resetConditional,
    handleMatchChange,
    handleTargetChange,
    handleCaseToggle,
    handleFlippedToggle,
    // Subjects
    subjects,
    formattedSubjects,
    // Conditional Types
    events,
    getSubjectValue,
    // Forms
    valid,
    form
  };
};

export default useConditionalReducer;
export { setConditionals, reducer };
