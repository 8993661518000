import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const SetCredentialsContent = ({ content, onChange }) => {
  const handleEmailChange = email => {
    onChange({ email });
  };

  const handlePasswordChange = password => {
    onChange({ password });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          aria-label="Email"
          placeholder="Email"
          value={content.email}
          onChange={handleEmailChange}
        />
      </Flex>
      <Span>and</Span>
      <Flex col fat>
        <TemplatingTextInput
          aria-label="Password"
          placeholder="Password"
          value={content.password}
          onChange={handlePasswordChange}
        />
      </Flex>
    </Flex>
  );
};

SetCredentialsContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired
  }).isRequired
};

export default SetCredentialsContent;
