import React from 'react';

const Alexa = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="-25.98705 -43.33325 225.2211 259.9995"
    {...props}>
    <path d="M86.623 0C38.783 0 0 38.803 0 86.667c0 43.947 32.694 80.248 75.074 85.898v-17.463c0-4.925-3.104-9.35-7.77-10.917-24.452-8.218-41.945-31.601-41.3-58.98.784-33.332 27.922-59.538 61.244-59.202 33.2.336 60.012 27.367 60.012 60.664 0 .759-.019 1.514-.047 2.265l-.018.507a63.499 63.499 0 01-.13 2.086c-.016.22-.038.44-.058.66a56.912 56.912 0 01-.355 3.067c-.033.246-.067.493-.104.738-7.424 48.927-71.118 76.426-71.467 76.577 3.777.502 7.629.766 11.542.766 47.84 0 86.624-38.801 86.624-86.666C173.247 38.803 134.464 0 86.623 0" />
  </svg>
);

export default Alexa;
