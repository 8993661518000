import DynamicInput from './DynamicInput';

const action = {
  id: 'dynamic',
  name: 'Dynamic',
  Input: DynamicInput,
  setInitialContent: content => {
    const defaultTemplate = `[
  {
    "type": "standard",
    "content": {
      "text": ""
    }
  }
]`;

    return {
      template: content.template || defaultTemplate
    };
  },
  valid: content => content.template.length > 0,
  snippet: ``
};

export default action;
