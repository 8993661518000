import React from 'react';
import { Redirect } from 'react-router-dom';

import { useQuery, useQuerySearch } from '@ubisend/pulse-hooks';

const FilesRedirect = ({ location }) => {
  const query = useQuery('files/sources');

  const subscriberId = useQuerySearch(location, 'subscriber_id');

  if (!query.isSuccess) {
    return null;
  }

  const buildLink = () => {
    const baseLink = `/files/${query.data.data[0].id}/view`;

    if (subscriberId) {
      return baseLink + `?subscriber_id=${subscriberId}`;
    }

    return baseLink;
  };

  return <Redirect to={buildLink()} />;
};

export default FilesRedirect;
