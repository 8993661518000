import ProgressInput from './ProgressInput';

const progress = {
  id: 'progress',
  name: 'Progress',
  Input: ProgressInput,
  setInitialContent: content => {
    return {
      text: content.text || ''
    };
  },
  valid: content => {
    return content.text.length > 0;
  },
  snippet: `{
    "type": "progress",
    "content": {
      "text": ""
    }
}`
};

export default progress;
