import EmbedInput from './EmbedInput';

const embed = {
  id: 'embed',
  name: 'Embed',
  Input: EmbedInput,
  setInitialContent: content => {
    return {
      text: content.text || '',
      title: content.title || '',
      url: content.url || '',
      content_description: content.content_description || ''
    };
  },
  valid: content => {
    return (
      content.text.length > 0 &&
      content.title.length > 0 &&
      content.url.length > 0
    );
  },
  snippet: `{
    "type": "embed",
    "content": {
      "text": "",
      "title": "",
      "url": "",
      "content_description": ""
    }
}`
};

export default embed;
