import React from 'react';

import { ButtonSelect } from '@ubisend/pulse-components';
import { useTemplating } from '@ubisend/pulse-templating';

import responses from '../../../Types/index';

const responseWithSnippets = responses.all.filter(response => {
  return Boolean(response.snippet);
});

const AddResponseTag = () => {
  const templating = useTemplating();

  const handleAddForTag = ({ value }) => {
    templating.dispatch({ type: templating.TYPES.ADD_SNIPPET, snippet: value });
  };

  const format = response => {
    return {
      label: response.name,
      value: response.snippet
    };
  };

  return (
    <ButtonSelect
      onChange={handleAddForTag}
      value={null}
      options={responseWithSnippets.map(format)}
      placeholder="Add response..."
    />
  );
};

export default AddResponseTag;
