import MailtoInput from './MailtoInput';

const mailto = {
  id: 'mailto',
  name: 'Mailto',
  Input: MailtoInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "mailto", "label": "", "action": "", "linkback": ""}`
};

export default mailto;
