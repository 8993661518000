import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import {
  Flex,
  Button as ComponentLibraryButton
} from '@ubisend/pulse-components';

import DeleteButton from './DeleteButton';
import types, { getType } from './Types/index';
import { useButtons } from '../../hooks/index';

const Button = ({ button, index }) => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const { handleButtonMove, min, value } = useButtons();

  const [, drop] = useDrop({
    accept: 'BUTTON',
    drop(item) {
      if (item.index === index) {
        return;
      }

      handleButtonMove(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type: 'BUTTON',
    item: () => ({ index })
  });

  drag(buttonRef);
  drop(containerRef);

  const Component = useMemo(() => {
    const type = getType(button.type);

    return type.Input;
  }, [button]);

  const { type, ...content } = button;

  return (
    <Flex xSpace border padSm top ref={containerRef}>
      <ComponentLibraryButton
        variant="inline"
        icon="menuAlt4"
        ref={buttonRef}
      />
      <Component index={index} type={type} content={content} />
      {value.length > min && (
        <Flex>
          <DeleteButton index={index} />
        </Flex>
      )}
    </Flex>
  );
};

Button.propTypes = {
  button: PropTypes.shape({
    type: PropTypes.oneOf(types.all.map(type => type.id))
  }),
  index: PropTypes.number.isRequired
};

export default Button;
