import ManualImportEditor from './ManualImportEditor';
import ManualImportPreview from './ManualImportPreview';

const driver = {
  wizard: {
    steps: [],
    success:
      'Created a new manual knowledge base. Click on the item in the sidebar to create a new section.'
  },
  settings: {
    initial: {},
    valid: () => true
  },
  content: {
    Editor: ManualImportEditor,
    Preview: ManualImportPreview
  },
  show_polling_interval: false
};

export default driver;
