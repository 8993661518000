import React from 'react';
import PropTypes from 'prop-types';

import { TemplatingTextInput } from '@ubisend/pulse-templating';

const NumberCondition = ({ onChange, ...props }) => {
  const handleChange = value => {
    onChange(value);
  };

  return (
    <TemplatingTextInput onChange={handleChange} placeholder="20" {...props} />
  );
};

NumberCondition.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default NumberCondition;
