import { useReducer, useMemo, useCallback } from 'react';

import { useLanguages } from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import responses from '../Components/Messages/responses';

const getResponse = type => {
  return responses.find(response => response.id === type);
};

const isValid = ({ type, language_id, composer, content }) => {
  if (!type || !language_id || !composer) {
    return false;
  }

  const response = getResponse(type);

  return response.valid(content);
};

const TYPES = {
  // Language
  SELECT_LANGUAGE: 'SELECT_LANGUAGE',
  // Responses
  ADD_RESPONSE: 'ADD_RESPONSE',
  SELECT_RESPONSE: 'SELECT_RESPONSE',
  MOVE_RESPONSE: 'MOVE_RESPONSE',
  DELETE_RESPONSE: 'DELETE_RESPONSE',
  // Response
  CHANGE_TYPE: 'CHANGE_TYPE',
  CHANGE_COMPOSER: 'CHANGE_COMPOSER',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  CHANGE_SUBJECT: 'CHANGE_SUBJECT',
  // Settings
  TOGGLE_SETTINGS: 'TOGGLE_SETTINGS',
  OPEN_SETTINGS: 'OPEN_SETTINGS',
  CLOSE_SETTINGS: 'CLOSE_SETTINGS',
  SAVE_SETTINGS: 'SAVE_SETTINGS'
};

const setInitialSubject = (currentSubject, subjects) => {
  if (!currentSubject) {
    return null;
  }

  const { toState } = subjects.find(({ subject }) => {
    return subject.type === currentSubject.type;
  });

  return toState(currentSubject);
};

const setInitialContent = (type, content) => {
  const response = getResponse(type);

  return response.setInitialContent(content || {});
};

const defaultResponse = {
  subject: null,
  type: 'standard',
  composer: null,
  content: setInitialContent('standard')
};

const reducer = (state, { type, ...params }) => {
  const updateResponse = callback => {
    return {
      ...state,
      responses: state.responses.map(response => {
        if (response.id === state.current) {
          return { ...response, ...callback(response) };
        }

        return response;
      })
    };
  };

  const getResponses = languageId => {
    return state.responses.filter(
      response => response.language_id === languageId
    );
  };

  const getNextId = () => {
    return Math.max(...state.responses.map(response => response.id)) + 1;
  };

  const hasResponses = languageId => {
    return Boolean(
      state.responses.find(response => response.language_id === languageId)
    );
  };

  const addResponse = languageId => {
    const id = getNextId();

    return {
      responses: state.responses.concat({
        ...defaultResponse,
        id,
        language_id: languageId
      }),
      current: id
    };
  };

  switch (type) {
    // Languages
    case TYPES.SELECT_LANGUAGE: {
      const responseExists = hasResponses(params.language.id);

      // Select the new language, also selecting the first
      // response of that language.
      if (responseExists) {
        return {
          ...state,
          language: params.language,
          current: getResponses(params.language.id)[0].id
        };
      }

      // Handle if no responses exists for the selected language, adding a
      // new default response
      return {
        ...state,
        ...addResponse(params.language.id),
        language: params.language
      };
    }

    // Responses
    case TYPES.ADD_RESPONSE:
      return {
        ...state,
        ...addResponse(state.language.id)
      };
    case TYPES.SELECT_RESPONSE:
      return { ...state, current: params.id };
    case TYPES.MOVE_RESPONSE: {
      const mutableResponses = [...state.responses];
      mutableResponses.splice(
        params.toIndex,
        0,
        mutableResponses.splice(params.fromIndex, 1)[0]
      );

      return {
        ...state,
        responses: mutableResponses
      };
    }
    case TYPES.DELETE_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          response => response.id !== params.id
        ),
        // If not deleting the selected responses, leave it, otherwise
        // choose the next response for the selected language.
        current:
          params.id !== state.current
            ? state.current
            : getResponses(state.language.id).find(
                response => response.id !== state.current
              ).id
      };

    case TYPES.CHANGE_TYPE: {
      return updateResponse(response => {
        const builtType = setInitialContent(params.newType);
        const handOverText =
          Object.prototype.hasOwnProperty.call(builtType, 'text') &&
          response.content.text;

        return {
          type: params.newType,
          content: {
            ...builtType,
            // Pass across the text value if the new type has a text input.
            ...(handOverText && { text: response.content.text })
          }
        };
      });
    }
    case TYPES.CHANGE_COMPOSER:
      return updateResponse(() => ({ composer: params.newComposer }));
    case TYPES.UPDATE_CONTENT:
      return updateResponse(response => {
        return { content: { ...response.content, ...params.newContent } };
      });
    case TYPES.CHANGE_SUBJECT:
      return updateResponse(() => ({ subject: params.newSubject }));

    // Settings
    case TYPES.OPEN_SETTINGS:
      return {
        ...state,
        showSettings: true,
        // Store a reference to the value of the subject when they open the settings.
        settingsRef: state.responses[state.current].subject
      };
    case TYPES.CLOSE_SETTINGS: {
      const ref = state.settingsRef;

      return {
        // If they close the settings (not save them) restore the settings
        // back to the original ref.
        ...updateResponse(() => ({ subject: ref })),
        showSettings: false,
        settingsRef: null
      };
    }
    case TYPES.TOGGLE_SETTINGS:
      return { ...state, showSettings: !state.showSettings };
    case TYPES.SAVE_SETTINGS:
      return { ...state, showSettings: false, settingsRef: null };

    // Errors
    default:
      throw new Error(`No event defined in useComposerReducer for ${type}`);
  }
};

const useComposerReducer = ({ responses, subjects = [] }) => {
  const { defaultLanguage, languages } = useLanguages();
  const { hasPermission } = useAuth();

  const defaultResponses =
    responses?.length > 0 ? responses : [defaultResponse];

  const initialResponses = defaultResponses.map((response, key) => ({
    id: key,
    language_id: response.language?.id || defaultLanguage.id,
    subject: setInitialSubject(response.subject, subjects),
    type: response.type || 'standard',
    composer: response.composer,
    content: setInitialContent(response.type, response.content)
  }));

  const [state, dispatch] = useReducer(reducer, {
    current: initialResponses.find(
      response => response.language_id === defaultLanguage.id
    ).id,
    showSettings: false,
    language: defaultLanguage,
    responses: initialResponses
  });

  const getResponses = useCallback(
    language => {
      return state.responses.filter(
        ({ language_id }) => language_id === language.id
      );
    },
    [state.responses]
  );

  const isValidLanguage = useCallback(
    language => {
      const responses = getResponses(language);
      return responses.length > 0 && responses.every(isValid);
    },
    [getResponses]
  );

  const valid = useMemo(() => languages.every(isValidLanguage), [
    languages,
    isValidLanguage
  ]);

  const getSubject = type => {
    return subjects.find(({ subject }) => subject.type === type);
  };

  const formattedSubjects = subjects.map(({ name, subject, permission }) => {
    return {
      label: name,
      value: subject.type,
      isDisabled: !hasPermission(permission)
    };
  });

  const format = useCallback(
    chosenSubject => {
      if (!chosenSubject) {
        return null;
      }

      const { toApi } = subjects.find(({ subject }) => {
        return subject.type === chosenSubject.type;
      });

      return toApi(chosenSubject);
    },
    [subjects]
  );

  const form = useMemo(() => {
    if (!valid) {
      return null;
    }

    const responses = state.responses.map(message => ({
      language_id: message.language_id,
      type: message.type,
      composer_id: message.composer ? message.composer.id : null,
      content: message.content,
      subject: format(message.subject)
    }));

    return { responses };
  }, [state.responses, format, valid]);

  return {
    ...state,
    response: state.responses.find(response => response.id === state.current),
    responses: getResponses(state.language),
    dispatch,
    subjects,
    getSubject,
    formattedSubjects,
    form,
    valid,
    isValidLanguage
  };
};

export default useComposerReducer;
