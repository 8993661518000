import EventInput from './EventInput';

const event = {
  id: 'event',
  name: 'Event',
  Input: EventInput,
  setInitialContent: content => {
    return {
      text: content.text || ''
    };
  },
  valid: content => {
    return content.text.length > 0;
  },
  snippet: `{
    "type": "event",
    "content": {
      "text": "",
    }
}`
};

export default event;
