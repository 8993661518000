import GeneralInput from './GeneralInput';
import { valid as validButton, setButton } from '../../../Buttons/Types/index';

const general = {
  id: 'general',
  name: 'General',
  Input: GeneralInput,
  setInitialContent: content => {
    const responses = content.responses || [];

    return {
      title: content.title || '',
      subtitle: content.subtitle || '',
      image: content.image || 'http://placehold.it/400x200',
      image_description: content.image_description || '',
      url: content.url || '',
      responses: responses.map(setButton)
    };
  },
  valid: content => {
    return (
      content.title.length > 0 &&
      content.subtitle.length > 0 &&
      content.image.length > 0 &&
      content.url.length > 0 &&
      content.responses.length > 0 &&
      // TODO: Validate per button type
      content.responses.every(validButton)
    );
  },
  snippet: `{
    "type": "general",
    "content": {
      {
        "title": "",
        "subtitle": "",
        "image": "http://placehold.it/400x200",
        "image_description": "",
        "url": "",
        "responses": [
          {"type": "postback", "label": "", "action": ""}
        ]
      }
    }
}`
};

export default general;
