import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { useNotification, Flex } from '@ubisend/pulse-components';

import { syncStepActions } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';
import Modal from '../../../Modal';
import ActionDetails from './ActionDetails';

const AddActionBlock = ({ handleClose, node }) => {
  const { showSuccess } = useNotification();
  const { dispatch: builderDispatch } = useBuilder();

  const mutation = useMutation(syncStepActions, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added a new action');
      builderDispatch({
        type: 'SYNC_STEP_ACTIONS',
        stepId: node.id,
        newActions: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate({
      stepId: node.id,
      actions: node.base.actions.concat(form)
    });
  };

  return (
    <Modal header="Add action" handleClose={handleClose}>
      <Flex pad col>
        <ActionDetails
          handleSubmit={handleSubmit}
          loading={mutation.isLoading}
          initialActions={{ name: '', content: {} }}
        />
      </Flex>
    </Modal>
  );
};

AddActionBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
};

export default AddActionBlock;
