import PaymentInput from './PaymentInput';

const payment = {
  id: 'payment',
  name: 'Payment',
  Input: PaymentInput,
  setInitialContent: content => {
    return {
      processor: content.processor || null,
      content_description: content.content_description || ''
    };
  },
  valid: content => {
    return Boolean(content.processor);
  },
  snippet: `{
    "type": "payment",
    "content": {
      "processor": "",
      "content_description": "",
    }
}`
};

export default payment;
