import DynamicInput from './DynamicInput';

const dynamic = {
  id: 'dynamic',
  name: 'Dynamic',
  Input: DynamicInput,
  setInitialContent: content => {
    const defaultTemplate = `{
"type": "message",
"content": {
  "placeholder": "Send a message",
  "disabled": false
}
}`;
    return {
      template: content.template || defaultTemplate
    };
  },
  formatContent: content => {
    return {
      template: content.template
    };
  },
  valid: content => Boolean(content.template),
  snippet: null
};

export default dynamic;
