import action from './Action/index';
import carousel from './Carousel/index';
import content from './Content/index';
import embed from './Embed/index';
import event from './Event/index';
import general from './General/index';
import image from './Image/index';
import payment from './Payment/index';
import progress from './Progress/index';
import quickReply from './QuickReply/index';
import standard from './Standard/index';
import video from './Video/index';

const allTypes = {
  action,
  carousel,
  content,
  embed,
  event,
  general,
  image,
  payment,
  progress,
  quickReply,
  standard,
  video
};

const types = {
  ...allTypes,
  all: [
    allTypes.action,
    allTypes.carousel,
    allTypes.content,
    allTypes.embed,
    allTypes.event,
    allTypes.general,
    allTypes.image,
    allTypes.payment,
    allTypes.progress,
    allTypes.quickReply,
    allTypes.standard,
    allTypes.video
  ]
};

export default types;
