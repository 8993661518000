import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Panel,
  Explainer,
  Flex,
  Label,
  TextInput,
  Button,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

const AlexaLink = () => {
  const query = useQuery('alexa/settings');
  const { copy, copied } = useCopyToClipboard();

  const handleCopy = () => {
    copy(query.data.data.url);
  };

  return (
    <PageWrapper center header="Alexa">
      <Flex col center>
        <OneHalfLayout>
          <Panel divider header="Link your Alexa Skill">
            <Explainer mb>TODO</Explainer>
            {query.isLoading && <Placeholder items={1} subitems={2} />}
            {query.isSuccess && (
              <Flex col>
                <Label htmlFor="api">API endpoint</Label>
                <Flex>
                  <TextInput id="api" disabled value={query.data.data.url} />
                  <Button
                    disabled={copied}
                    icon="clipBoard"
                    onClick={handleCopy}>
                    {copied ? 'Copied' : 'Copy'}
                  </Button>
                </Flex>
              </Flex>
            )}
          </Panel>
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default AlexaLink;
