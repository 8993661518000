import ContentInput from './ContentInput';

const content = {
  id: 'content',
  name: 'Content',
  Input: ContentInput,
  setInitialContent: content => {
    return {
      text: content.text || '',
      title: content.title || '',
      markdown: content.markdown || ''
    };
  },
  valid: content => {
    return (
      content.text.length > 0 &&
      content.title.length > 0 &&
      content.markdown.length > 0
    );
  },
  snippet: `{
    "type": "content",
    "content": {
      "text": "",
      "title": "",
      "markdown": ""
    }
}`
};

export default content;
