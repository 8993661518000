import FileInput from './FileInput';

const file = {
  id: 'file',
  name: 'File',
  Input: FileInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "file", "label": "", "action": "", "linkback": ""}`
};

export default file;
