import UploadPdf from './UploadPdf';
import PdfImportPreview from './PdfImportPreview';
import PdfImportEditor from './PdfImportEditor';

const steps = [{ title: 'Upload your PDF document', Component: UploadPdf }];

const driver = {
  wizard: {
    steps,
    success:
      'Successfully started PDF import. Check back later to see your content in the platform.'
  },
  settings: {
    initial: { path: '' },
    valid: settings => Boolean(settings.path)
  },
  content: {
    Editor: PdfImportEditor,
    Preview: PdfImportPreview
  },
  show_polling_interval: false
};

export default driver;
