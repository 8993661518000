import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import chroma from 'chroma-js';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  AnimateSharedLayout,
  AnimatePresence,
  motion
} from '@ubisend/framer-motion';
import {
  Flex,
  Divider,
  Span,
  Placeholder,
  SecondarySidebarContainer,
  Heading1,
  Tag,
  Label,
  PanelSlider,
  Heading3,
  InnerPanel,
  Paragraph,
  Indicator,
  Button,
  FilterMenu,
  useFilterReducer
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { ActiveHours } from '@ubisend/pulse-tickets';
import {
  useSubscription,
  useNotifySubscription
} from '@ubisend/pulse-subscribe';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useCurrentTimeZone } from '@ubisend/pulse-hooks';

import chime from '../../../sounds/chime.mp3';
import {
  useBrowser,
  useDocument,
  useTicketMonitor,
  useUpdateTicketCache,
  useUpdateTicketResponsesCache
} from '../../../hooks/index';
import { LiveChatSettings } from '../../../Components/index';

const MessagePreview = styled(Paragraph)`
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  -webkit-line-clamp: 3;
`;

const statuses = {
  in_progress: 'Claimed',
  submitted: 'Pending'
};

const Status = ({ children }) => {
  return (
    <div style={{ position: 'sticky', top: 0, background: 'white' }}>
      <Divider />
      <Flex middle style={{ marginTop: '-1.5rem' }}>
        <Label
          colour="grey"
          style={{ background: 'white', padding: '0 1rem' }}
          secondary>
          {children}
        </Label>
      </Flex>
    </div>
  );
};

const HeadingContainer = styled(Flex)`
  ${tw`border-grey-medium border-solid border-0 border-b`}
`;

const SidebarLinkContainer = styled(motion.div)`
  &:hover {
    background: ${({ theme }) => {
      return chroma(theme.white).darken(0.25);
    }};
  }
  ${props =>
    props.unread &&
    `background: ${chroma.mix(props.theme.primary, 'white', 0.75)};`}
  ${props =>
    props.active && `background: ${chroma(props.theme.white).darken(0.25)};`}
  & > a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const REFRESH_INTERVAL = 8000;

const Sidebar = ({ agent }) => {
  const sound = useRef(new Audio(chime));

  const location = useLocation();
  const { notify } = useBrowser();
  const { setTitle } = useDocument();
  const updateTicketCache = useUpdateTicketCache();
  const updateTicketResponsesCache = useUpdateTicketResponsesCache();
  const timeZone = useCurrentTimeZone();

  const filters = useFilterReducer({
    id: 'live-chat-tickets',
    initialFilters: { group_ids: [] }
  });

  const [showSettings, setShowSettings] = useState(false);
  const [unreadTicketIds, setUnreadTicketIds] = useState([]);

  const playAudio = useCallback(() => {
    if (!agent.sound_enabled) {
      return;
    }

    sound.current.pause();
    sound.current.currentTime = 0;
    sound.current.play();
  }, [agent]);

  const isActiveTicket = useCallback(
    ticket => {
      return location.pathname === `/live-chat/${ticket.id}`;
    },
    [location.pathname]
  );

  const query = useQuery(
    [
      'tickets/ubidesk',
      {
        page: 1,
        ticket_types: ['live-chat'],
        statuses: ['submitted', 'in_progress'],
        assignee_ids: [agent.id, null],
        order: 'status',
        direction: 'desc',
        ...filters.form
      }
    ],
    {
      refetchIntervalInBackground: REFRESH_INTERVAL,
      refetchInterval: REFRESH_INTERVAL
    }
  );

  const handleMessage = useCallback(
    ({ responses }) => {
      if (!query.isSuccess) {
        return;
      }

      const ticket = query.data.data.find(
        ticket => ticket.subscriber.id === responses[0].subscriber.id
      );

      if (!ticket) {
        return;
      }

      if (!isActiveTicket(ticket)) {
        setUnreadTicketIds(unreadTicketIds => {
          return unreadTicketIds.concat(ticket.id);
        });
      }

      updateTicketResponsesCache({ ticketId: ticket.id, responses });
      playAudio();
    },
    [query, updateTicketResponsesCache, isActiveTicket, playAudio]
  );

  const handleSubscriberLeavingChat = useCallback(
    ({ ticket }) => {
      updateTicketCache({
        ticketId: ticket.id,
        callback: oldTicket => {
          return {
            ...oldTicket,
            is_active: false
          };
        }
      });

      if (!isActiveTicket(ticket)) {
        setUnreadTicketIds(unreadTicketIds => {
          return unreadTicketIds.concat(ticket.id);
        });
      }
    },
    [updateTicketCache, isActiveTicket]
  );

  useSubscription('message', handleMessage);
  useNotifySubscription(
    'SUBSCRIBER_LEFT_LIVE_CHAT',
    handleSubscriberLeavingChat
  );

  const handleNewTickets = useCallback(
    newTickets => {
      notify({
        title: 'Live chat',
        body: `${newTickets.length} new ticket${
          newTickets.length > 1 ? 's' : ''
        }`
      });
      setTitle(`Live chat: new ticket`);
      setUnreadTicketIds(newTickets.map(ticket => ticket.id));
      playAudio();
    },
    [notify, setTitle, playAudio]
  );

  useTicketMonitor(query.isSuccess ? query.data.data : null, handleNewTickets);

  const handleGroupsChange = groups => {
    filters.updateFilters({
      group_ids: groups ? groups.map(group => group.value) : []
    });
  };

  const handleLinkClick = ticket => {
    setUnreadTicketIds(ids => ids.filter(id => id !== ticket.id));
  };

  return (
    <SecondarySidebarContainer style={{ boxShadow: 'none' }}>
      <AnimatePresence>
        {showSettings && (
          <PanelSlider
            header="Live chat settings"
            handleHide={() => setShowSettings(false)}
            width={'30rem'}>
            <Flex ySpace col mt>
              <>
                <LiveChatSettings />
                <Flex mb between center>
                  <Heading3>Active hours</Heading3>
                  {timeZone && <Tag>{timeZone}</Tag>}
                </Flex>
                <InnerPanel>
                  <ActiveHours query="/live-chat/active-hours" />
                </InnerPanel>
              </>
            </Flex>
          </PanelSlider>
        )}
      </AnimatePresence>
      <Flex col yScreen borderRight>
        <Flex fat mb>
          <Flex between fat>
            <HeadingContainer grow tall fat pad between center>
              <Heading1>Live chat</Heading1>
              <Flex col ySpace>
                <PermissionFilter can="view live chat settings">
                  <Button
                    variant="secondary"
                    icon="cog"
                    onClick={() => setShowSettings(true)}>
                    Settings
                  </Button>
                </PermissionFilter>
                <FilterMenu
                  buttonProps={{
                    fat: true,
                    loading: query.isLoading
                  }}
                  {...filters.props}>
                  <Flex col style={{ width: '20rem' }}>
                    <Label htmlFor="tags">Tags</Label>
                    <GroupSelect
                      id="tags"
                      for="tickets"
                      value={filters.filters.group_ids}
                      onChange={handleGroupsChange}
                      placeholder="Filter with tags"
                    />
                  </Flex>
                </FilterMenu>
              </Flex>
            </HeadingContainer>
          </Flex>
        </Flex>
        {query.isLoading && (
          <Flex col yScreen pad borderRight>
            <Placeholder />
          </Flex>
        )}
        {query.isSuccess && query.data.data.length === 0 && (
          <Flex pl pr mt>
            <Span light>No available live chats</Span>
          </Flex>
        )}
        {query.isSuccess && query.data.data.length > 0 && (
          <Flex col yScroll tall>
            <AnimateSharedLayout>
              {query.data.data.map((ticket, key) => {
                const isUnread = unreadTicketIds.includes(ticket.id);

                return (
                  <React.Fragment key={ticket.id}>
                    {key === 0 && <Status>{statuses[ticket.status]}</Status>}
                    {key !== 0 &&
                      ticket.status !== query.data.data[key - 1].status && (
                        <Status>{statuses[ticket.status]}</Status>
                      )}
                    <SidebarLinkContainer
                      id="container"
                      layoutId={ticket.id}
                      layout="position"
                      unread={isUnread}
                      active={isActiveTicket(ticket)}>
                      <Link
                        id="link"
                        to={`/live-chat/${ticket.id}`}
                        onClick={() => handleLinkClick(ticket)}>
                        <Flex col pad>
                          <Flex between fat center mbSm>
                            <Label
                              style={isUnread && { fontWeight: 700 }}
                              mb={false}>
                              User #{ticket.subscriber.id}
                            </Label>
                            <Flex xSpaceSm center>
                              {isUnread && <Indicator colour="primary" />}
                              <Label
                                style={isUnread && { fontWeight: 700 }}
                                colour={isUnread ? 'primary' : 'grey'}
                                mb={false}>
                                {dayjs(
                                  ticket.subscriber.latest_message.sent_at
                                ).from()}
                              </Label>
                            </Flex>
                          </Flex>
                          {ticket.groups.length > 0 && (
                            <Flex xSpaceSm mbSm>
                              {ticket.groups.map((group, key) => (
                                <Tag key={key}>{group.name}</Tag>
                              ))}
                            </Flex>
                          )}
                          <MessagePreview sm secondary={!isUnread}>
                            {ticket.subscriber.latest_message.content.text}
                          </MessagePreview>
                        </Flex>
                      </Link>
                    </SidebarLinkContainer>
                  </React.Fragment>
                );
              })}
            </AnimateSharedLayout>
          </Flex>
        )}
      </Flex>
    </SecondarySidebarContainer>
  );
};

Sidebar.propTypes = {
  agent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sound_enabled: PropTypes.bool.isRequired
  })
};

export default Sidebar;
