import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  OneThirdLayout,
  TwoThirdsLayout,
  InfoSection,
  Button,
  IconPanel,
  PanelSlider,
  useNotification,
  NoResults
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import GroupDetails from './GroupDetails';
import {
  createGroup as createGroupApi,
  updateGroup as updateGroupApi,
  deleteGroup as deleteGroupApi
} from '../api/index';

const scopes = {
  faqs: 'FAQ',
  tickets: 'Ticket',
  conversations: 'Conversation',
  links: 'Link'
};

const getCount = ({ scoped_to, scope_count }) => {
  if (scope_count === 1) {
    return `1 ${scoped_to.slice(0, -1)}`;
  }

  return `${scope_count} ${scoped_to}`;
};

const Scope = ({ groups, scope }) => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(null);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { mutate: createGroup } = useMutation(createGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Succesfully created new tag.');
    }
  });
  const { mutate: editGroup } = useMutation(updateGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Succesfully updated tag.');
    }
  });
  const { mutate: deleteGroup } = useMutation(deleteGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Succesfully deleted tag.');
    }
  });

  const handleAddNew = () => setCreate(true);

  const handleEdit = group => () => setEdit(group);

  const handleDelete = group => () => deleteGroup(group.id);

  const handleCreateGroup = details => {
    createGroup({ ...details, scoped_to: scope });
  };

  const handleEditGroup = details => editGroup({ id: edit.id, ...details });

  return (
    <Flex fat>
      <AnimatePresence>
        {create && (
          <PanelSlider
            width="40vw"
            header={`Create ${scopes[scope]} Tag`}
            handleHide={() => setCreate(false)}>
            <GroupDetails onSubmit={handleCreateGroup} />
          </PanelSlider>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {edit && (
          <PanelSlider
            width="40vw"
            header={`Edit ${scopes[scope]} Tag`}
            handleHide={() => setEdit(null)}>
            <GroupDetails onSubmit={handleEditGroup} initialName={edit.name} />
          </PanelSlider>
        )}
      </AnimatePresence>
      <OneThirdLayout>
        <InfoSection
          title={`${scopes[scope]} Tags`}
          info={`Categorise your ${scopes[scope]}s for better reporting and searching.`}
          actions={
            <PermissionFilter can="create tags">
              <Button onClick={handleAddNew}>Add New</Button>
            </PermissionFilter>
          }
        />
      </OneThirdLayout>
      <TwoThirdsLayout>
        {groups.length === 0 && (
          <NoResults text={`No ${scopes[scope]} tags created`} />
        )}
        {groups.map((group, key) => (
          <IconPanel
            key={key}
            icon={'tag'}
            title={group.name}
            status={group.scope_count ? getCount(group) : 'Not used'}
            actions={
              <Flex xSpace>
                <PermissionFilter can="edit tags">
                  <Button
                    onClick={handleEdit(group)}
                    variant="secondary"
                    icon="pencilAlt">
                    Edit
                  </Button>
                </PermissionFilter>
                <PermissionFilter can="delete tags">
                  <Button
                    variant="secondary"
                    colour="danger"
                    icon="trash"
                    onClick={handleDelete(group)}>
                    Delete
                  </Button>
                </PermissionFilter>
              </Flex>
            }
          />
        ))}
      </TwoThirdsLayout>
    </Flex>
  );
};

Scope.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scope_count: PropTypes.number,
      scope_to: PropTypes.oneOf(['faqs', 'tickets'])
    })
  ),
  scope: PropTypes.oneOf(['faqs', 'tickets'])
};

export default Scope;
