import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../../hooks/index';

const PostbackInput = ({ content, index }) => {
  const { handleButtonChange } = useButtons();

  return (
    <Flex col ySpace fat>
      <TemplatingTextInput
        aria-label={`postback-label-${index + 1}`}
        value={content.label}
        placeholder="Annual Leave"
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <TemplatingTextInput
        aria-label={`postback-action-${index + 1}`}
        value={content.action}
        placeholder="What is the annual leave policy?"
        onChange={value => {
          handleButtonChange(index, 'action', value);
        }}
      />
    </Flex>
  );
};

PostbackInput.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired
  }),
  index: PropTypes.number.isRequired
};

export default PostbackInput;
