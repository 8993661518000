import MessageInput from '../Message/MessageInput';

const numeric = {
  id: 'numeric',
  name: 'Numeric',
  Input: MessageInput,
  setInitialContent: content => {
    return {
      placeholder: content.placeholder || 'Enter a number...',
      disabled: content.disabled !== undefined ? content.disabled : false
    };
  },
  formatContent: content => {
    return {
      placeholder: content.placeholder,
      disabled: content.disabled
    };
  },
  valid: content => {
    return Boolean(content.placeholder);
  },
  snippet: `{
    "type": "numeric",
    "content": {
      "placeholder": "Enter a number...",
      "disabled": false
    } 
}`
};

export default numeric;
