import { useState, useMemo, useCallback, useEffect } from 'react';

const isValidJson = value => {
  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
};

const formatJson = value => {
  return JSON.stringify(JSON.parse(value), null, 4);
};

const defaultJson = '{}';

const defaultOptions = {
  debounce: 1500,
  formatInitialJson: true
};

const useJsonFormat = (
  initialValue = defaultJson,
  options = defaultOptions
) => {
  const [value, setValue] = useState(
    options.formatInitialJson
      ? formatJson(initialValue || defaultJson)
      : initialValue || defaultJson
  );

  const valid = useMemo(() => isValidJson(value), [value]);

  const formatJsonCallback = useCallback(() => {
    try {
      setValue(formatJson(value));
    } catch {
      //
    }
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(formatJsonCallback, options.debounce);

    return () => clearTimeout(timeout);
  }, [formatJsonCallback, options.debounce]);

  return [value, { setValue }, { valid }];
};

export default useJsonFormat;
