import PostbackInput from './PostbackInput';

const postback = {
  id: 'postback',
  name: 'Postback',
  Input: PostbackInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "postback", "label": "", "action": ""}`
};

export default postback;
