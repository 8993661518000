import React, { useCallback, useRef, useEffect } from 'react';

import { useTheme } from '@ubisend/pulse-volt';

import { useCanvas, useLanguage } from '../../hooks/index';
import {
  roundRect,
  nodeHeading,
  nodeFooterBorder,
  nodeHeaderBorder,
  circle,
  conditionalPreview,
  responses,
  RESPONSE_PREVIEW_PADDING,
  STEP_HEADER_HEIGHT,
  STEP_WIDTH,
  BLOCK_CONTAINER_HEIGHT,
  SIDEBAR_WIDTH
} from './renders/index';

// Compensate for HiDPI displays, otherwise canvas will render 1px per unit
const pixelRatio = window.devicePixelRatio || 1;

/**
 * The canvas layer is responsible for displaying all visual elements. The
 * canvas layer is broken down into util functions for rendering individual
 * elements.
 *
 * - Canvas rendering is performed using `window.requestAnimationFrame`, which
 *   tells the browser when to recalculate and paint a new frame. Using the
 *   `showPreview` field (explained more below) we know when the canvas view is
 *   static, so can stop our animation frame requests. Without this check, a new
 *   frame would be requested constantly every 16.66ms (1/60th second for
 *   60fps), greatly increasing CPU usage.
 *
 * - "constants.js" contains all the spacing and sizing information required for
 *   rendering. The canvas does not use a box model or hierarchical structure
 *   found in HTML so every reused variable for spacing is stored in this file
 *   to try and give some consistency to render functions, and eliminate the use
 *   of magic numbers.
 *
 * - Response Render functions take a CanvasContext object, render a response
 *   inside that context, and return the resulting height of rendered content.
 *   This is so the builder can keep track of the dimensions of nodes (Another
 *   gotcha of no box model), and ensure parity with the DOM layer. This is
 *   important so that click and drag events correspond correctly to their
 *   underlying nodes.
 *
 * - Conditions function similar to responses, in that they render the required
 *   elements and return the resulting height. Conditions are currently quite
 *   simple, consisting of just a black text box. This could be improved by
 *   revamping the text box util function.
 */
const Canvas = () => {
  const ref = useRef(null);

  const {
    nodes,
    links,
    getCurve,
    nodeSizes,
    panX,
    panY,
    zoomAmount,
    showPreview,
    setNodeSizes,
    getLinkDestinationOffset,
    getLinkOffset,
    newLinkOffset,
    newLinkDestinationOffset
  } = useCanvas();
  const botTheme = useTheme();
  const { language } = useLanguage();

  const drawRect = useCallback(
    (ctx, x, y, width, height) => {
      roundRect(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get(),
        width,
        height
      );
    },
    [panX, panY, zoomAmount]
  );

  const drawCircle = useCallback(
    (ctx, x, y, radius, colour, stroke) => {
      circle(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get(),
        radius,
        colour,
        stroke
      );
    },
    [panX, panY, zoomAmount]
  );

  const drawNodeHeader = useCallback(
    (ctx, x, y, node) => {
      nodeHeaderBorder(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get()
      );
      nodeHeading(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get(),
        node
      );
    },
    [panX, panY, zoomAmount]
  );

  const drawNodeFooter = useCallback(
    (ctx, x, y, height) => {
      nodeFooterBorder(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get(),
        height
      );
    },
    [panX, panY, zoomAmount]
  );

  const drawConditionPreview = useCallback(
    (ctx, x, y, node) => {
      return conditionalPreview(
        ctx,
        x + panX.get() / zoomAmount.get(),
        y + panY.get() / zoomAmount.get(),
        node
      );
    },
    [panX, panY, zoomAmount]
  );

  const drawPath = useCallback(
    (ctx, link) => {
      if (process.env.NODE_ENV === 'test') {
        return;
      }

      ctx.save();
      ctx.fillStyle = 'transparent';
      ctx.strokeStyle =
        link.to.type === 'validation'
          ? botTheme.danger
          : botTheme.gradient.from;
      ctx.lineWidth = 2;

      const curve = getCurve(
        link,
        panX.get() / zoomAmount.get(),
        panY.get() / zoomAmount.get()
      );
      const path = new Path2D(curve);
      ctx.fill(path);
      ctx.stroke(path);

      ctx.restore();
    },
    [getCurve, panX, panY, zoomAmount, botTheme.gradient.from, botTheme.danger]
  );

  const updateNodeSizes = useCallback(
    context => {
      // Get the heights of nodes by rendering once
      // Render functions return the height of the resulting object
      nodes.forEach(node => {
        if (node.type === 'step') {
          let height = STEP_HEADER_HEIGHT;
          height += RESPONSE_PREVIEW_PADDING * 2;
          height += responses(node, language, context, 50, 50, 'black');

          if (node.blocks.length > 0) {
            height += BLOCK_CONTAINER_HEIGHT;
          }

          setNodeSizes(currentSizes => {
            return currentSizes
              .filter(size => size.id !== node.id || size.type !== node.type)
              .concat({
                id: node.id,
                type: node.type,
                width: STEP_WIDTH,
                height
              });
          });
        }

        if (node.type === 'trigger' || node.type === 'transition') {
          // Call the render function once to get the height in the rendering context
          let height = conditionalPreview(context, 50, 50, node);
          if (node.blocks.length > 0) {
            height += BLOCK_CONTAINER_HEIGHT;
          }

          setNodeSizes(currentSizes => {
            return currentSizes
              .filter(size => size.id !== node.id || size.type !== node.type)
              .concat({
                id: node.id,
                type: node.type,
                width: STEP_WIDTH,
                height
              });
          });
        }

        if (node.type === 'validation') {
          // Call the render function once to get the height in the rendering context
          let height =
            conditionalPreview(context, 50, 50, node) +
            RESPONSE_PREVIEW_PADDING * 2;
          // Call the render function once to get the height in the rendering context
          height += responses(node, language, context, 50, 50, 'black');

          if (node.blocks.length > 0) {
            height += BLOCK_CONTAINER_HEIGHT;
          }
          setNodeSizes(currentSizes => {
            return currentSizes
              .filter(size => size.id !== node.id || size.type !== node.type)
              .concat({
                id: node.id,
                type: node.type,
                width: STEP_WIDTH,
                height
              });
          });
        }
      });

      // We dont actually want to display these renders, so we need to clear the canvas immediateley after
      context.clearRect(
        0,
        0,
        context.canvas.width / zoomAmount.get(),
        context.canvas.height / zoomAmount.get()
      );
    },
    [setNodeSizes, nodes, zoomAmount, language]
  );

  useEffect(() => {
    const canvas = ref.current;
    updateNodeSizes(canvas.getContext('2d'));
  }, [nodes, updateNodeSizes]);

  useEffect(() => {
    const canvas = ref.current;
    canvas.style.transform = `scale(${1 / pixelRatio})`;
    canvas.style.transformOrigin = 'top left';
    canvas.width = (window.innerWidth - SIDEBAR_WIDTH) * pixelRatio;
    canvas.height = window.innerHeight * pixelRatio;
    const context = canvas.getContext('2d');
    let animationFrameId;
    const render = () => {
      // Clear the existing canvas
      context.clearRect(
        0,
        0,
        context.canvas.width / zoomAmount.get(),
        context.canvas.height / zoomAmount.get()
      );
      // Scale canvas units according to the current zoom
      context.setTransform(
        zoomAmount.get() * pixelRatio,
        0,
        0,
        zoomAmount.get() * pixelRatio,
        0,
        0
      );
      // Draw Links
      links.filter(Boolean).forEach(link => {
        drawPath(context, link);
      });
      // Draw Nodes
      nodes.forEach(node => {
        const nodeSize = nodeSizes.find(
          info => info.id === node.id && info.type === node.type
        );
        if (!nodeSize) {
          return;
        }

        const { width, height } = nodeSize;
        const x = node.x - width / 2;
        const y = node.y - height / 2;
        // Draw a container for this node
        drawRect(context, x, y, width, height);

        if (node.type === 'step') {
          // Draw the title and subtitle for step nodes
          drawNodeHeader(context, x, y, node);

          // Draw the response preview
          responses(
            node,
            language,
            context,
            x + panX.get() / zoomAmount.get(),
            y + panY.get() / zoomAmount.get() + STEP_HEADER_HEIGHT,
            botTheme.gradient.from
          );
          // Draw connectors
          links
            .filter(
              link => link.to.id === node.id && link.to.type === node.type
            )
            .forEach(link => {
              drawCircle(
                context,
                link.to.x + getLinkDestinationOffset(link),
                link.to.y + 1,
                6,
                link.to.type === 'validation'
                  ? botTheme.danger
                  : botTheme.gradient.from
              );
            });
          // Draw outgoing connectors
          links
            .filter(
              link => link.from.id === node.id && link.from.type === node.type
            )
            .forEach(link => {
              drawCircle(
                context,
                link.from.x + getLinkOffset(link),
                link.from.y - 1,
                6,
                link.to.type === 'validation'
                  ? botTheme.danger
                  : botTheme.gradient.from
              );
            });
          // Draw unlinked connectors
          drawCircle(
            context,
            node.x + newLinkOffset(node),
            node.y + height / 2 - 1,
            6,
            'white',
            botTheme.gradient.from
          );
          drawCircle(
            context,
            node.x + newLinkDestinationOffset(node),
            node.y - height / 2 + 1,
            6,
            'white',
            botTheme.gradient.from
          );
        }

        if (node.type === 'transition' || node.type === 'trigger') {
          drawConditionPreview(context, x, y, node);
        }

        if (node.type === 'validation') {
          const height = drawConditionPreview(context, x, y, node);

          // Draw the response preview
          responses(
            node,
            language,
            context,
            x + panX.get() / zoomAmount.get(),
            y + panY.get() / zoomAmount.get() + height,
            botTheme.gradient.from
          );
        }

        if (node.blocks.length > 0) {
          // Draw the bottom bar for containing blocks
          drawNodeFooter(context, x, y, height);
        }
      });
      if (showPreview) {
        animationFrameId = window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [
    showPreview,
    drawRect,
    drawCircle,
    drawPath,
    drawConditionPreview,
    nodes,
    links,
    nodeSizes,
    zoomAmount,
    drawNodeFooter,
    drawNodeHeader,
    botTheme.gradient.from,
    botTheme.danger,
    getLinkDestinationOffset,
    getLinkOffset,
    newLinkDestinationOffset,
    newLinkOffset,
    panX,
    panY,
    language
  ]);

  return <canvas ref={ref} style={{ position: 'absolute' }} />;
};

export default Canvas;
