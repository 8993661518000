import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  OneThirdLayout,
  Flex,
  TwoThirdsLayout,
  InfoSection,
  NoResults,
  Panel,
  PanelSlider,
  useNotification,
  IconPanel,
  Button
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  createIntegration,
  editIntegration,
  deleteIntegration
} from '../../api/index';
import { useIntegrations } from '../../hooks/index';
import IntegrationDetails from './IntegrationDetails';
import DeleteIntegrationModal from './DeleteIntegrationModal';
import Endpoints from './Endpoints';

const Integrations = () => {
  const {
    integrations,
    create,
    setCreate,
    edit,
    setEdit,
    viewEndpoints,
    setViewEndpoints,
    getIntegration
  } = useIntegrations();

  const { showSuccess } = useNotification();
  const [deleting, setDeleting] = useState(false);

  const history = useHistory();
  const { state } = useLocation();

  const initialIntegration = state?.integrationId;
  const initialShowEndpoints = Boolean(state?.showEndpoints);

  const queryClient = useQueryClient();

  const handleCreateIntegration = async params => {
    await createIntegration(params);

    setCreate(false);
    showSuccess(`Successfully integrated the new integration "${params.name}"`);
    queryClient.invalidateQueries('integrations');
  };

  const handleEditIntegration = async params => {
    await editIntegration(edit.id, params);

    setEdit(null);
    showSuccess(`Successfully edited the integration "${params.name}"`);
    queryClient.invalidateQueries('integrations');
  };

  const handleDeleteIntegration = async ({ id, name }) => {
    await deleteIntegration(id);

    setDeleting(false);
    showSuccess(`Successfully deleted the integration "${name}"`);
    queryClient.invalidateQueries('integrations');
  };

  const replaceState = useCallback(
    state => {
      history.replace({ state });
    },
    [history]
  );

  useEffect(() => {
    if (parseInt(initialIntegration)) {
      if (initialShowEndpoints) {
        replaceState({});
        return setViewEndpoints(parseInt(initialIntegration));
      }
      replaceState({});
      return setEdit(getIntegration(parseInt(initialIntegration)));
    }
  }, [
    initialIntegration,
    initialShowEndpoints,
    setViewEndpoints,
    getIntegration,
    setEdit,
    replaceState
  ]);

  return (
    <Flex>
      {deleting && (
        <DeleteIntegrationModal
          name={deleting.name}
          handleSubmit={() => handleDeleteIntegration(deleting)}
          handleClose={() => setDeleting(false)}
        />
      )}
      <AnimatePresence>
        {create && (
          <PanelSlider
            header="Create Integration"
            handleHide={() => setCreate(false)}>
            <IntegrationDetails handleSubmit={handleCreateIntegration} />
          </PanelSlider>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {edit && (
          <PanelSlider
            header="Edit Integration"
            handleHide={() => setEdit(null)}>
            <IntegrationDetails
              handleSubmit={handleEditIntegration}
              initialName={edit.name}
              initialBaseUrl={edit.base_url}
              initialHeaders={edit.headers}
              initialShowAuth={false}
            />
          </PanelSlider>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {viewEndpoints && (
          <PanelSlider
            header="Configure Endpoints"
            handleHide={() => setViewEndpoints(null)}>
            <Endpoints />
          </PanelSlider>
        )}
      </AnimatePresence>
      <OneThirdLayout>
        <InfoSection
          title="Active integrations"
          info="Unlock powerful actions by integrating your chatbot to external applications."
          actions={
            <PermissionFilter can="create integrations">
              <Button onClick={() => setCreate(true)}>Add New</Button>
            </PermissionFilter>
          }
        />
      </OneThirdLayout>
      <TwoThirdsLayout>
        <Flex col ySpace>
          {!integrations && (
            <>
              <Panel loading loadingItems={1} />
              <Panel loading loadingItems={1} />
            </>
          )}
          {integrations && integrations.length === 0 && (
            <NoResults
              text="No integrations are connected"
              subtitle={'Create an integration to set up custom functionality'}
            />
          )}
          {integrations &&
            integrations.length > 0 &&
            integrations.map((integration, key) => (
              <IconPanel
                key={key}
                icon={'link'}
                title={integration.name}
                status={'Active'}
                actions={
                  <Flex xSpace>
                    <PermissionFilter can="edit integrations">
                      <Button
                        onClick={() => setEdit(integration)}
                        variant="secondary"
                        icon="pencilAlt">
                        Edit
                      </Button>
                    </PermissionFilter>
                    <Button
                      variant="secondary"
                      onClick={() => setViewEndpoints(integration.id)}>
                      Endpoints
                    </Button>
                    <PermissionFilter can="delete integrations">
                      <Button
                        variant="secondary"
                        colour="danger"
                        icon="trash"
                        onClick={() => setDeleting(integration)}>
                        Delete
                      </Button>
                    </PermissionFilter>
                  </Flex>
                }
              />
            ))}
        </Flex>
      </TwoThirdsLayout>
    </Flex>
  );
};

export default Integrations;
