import CalltoInput from './CalltoInput';

const callto = {
  id: 'callto',
  name: 'Callto',
  Input: CalltoInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "callto", "label": "", "action": "", "linkback": ""}`
};

export default callto;
