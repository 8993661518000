import ListenForResponseOnAlexaContent from './ListenForResponseOnAlexaContent';

const listenForResponseOnAlexa = {
  name: 'Listen for response on Alexa',
  Content: ListenForResponseOnAlexaContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default listenForResponseOnAlexa;
