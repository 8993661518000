import UrlInput from './UrlInput';

const url = {
  id: 'url',
  name: 'Url',
  Input: UrlInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "url", "label": "", "action": "", "linkback": ""}`
};

export default url;
