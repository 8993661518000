import { useReducer, useCallback, useMemo } from 'react';

import { useAuth } from '@ubisend/pulse-auth';

const TYPES = {
  //  General Details
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_DEFAULT_VALUE: 'UPDATE_DEFAULT_VALUE',
  UPDATE_VARIABLE_TYPE: 'UPDATE_VARIABLE_TYPE',
  // Subjects
  CHANGE_SUBJECT_TYPE: 'CHANGE_SUBJECT_TYPE',
  CHANGE_SUBJECT: 'CHANGE_SUBJECT',
  // Scopes
  CHANGE_SCOPE: 'CHANGE_SCOPE',
  // Settings Modal
  OPEN_SETTINGS: 'OPEN_SETTINGS',
  CLOSE_SETTINGS: 'CLOSE_SETTINGS',
  SAVE_SETTINGS: 'SAVE_SETTINGS',
  RESET_SETTINGS: 'RESET_SETTINGS'
};

const setInitialSubject = (currentSubject, subjects) => {
  if (!currentSubject) {
    return null;
  }

  const { toState } = subjects.find(({ subject }) => {
    return subject.type === currentSubject.type;
  });

  return toState(currentSubject);
};

const reducer = (state, { subjects, type, types, scopes, ...params }) => {
  const getSubject = type => {
    return subjects.find(({ subject }) => subject.type === type).subject;
  };

  const getType = type => {
    return types.find(({ key }) => key === type);
  };

  switch (type) {
    // General Details
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };

    // Variable type
    case TYPES.UPDATE_VARIABLE_TYPE:
      return {
        ...state,
        type: params.variableType,
        default_value: getType(params.variableType).default_value
      };
    case TYPES.UPDATE_DEFAULT_VALUE:
      return { ...state, default_value: params.defaultValue };

    // Subjects
    case TYPES.CHANGE_SUBJECT_TYPE:
      return { ...state, subject: getSubject(params.newSubjectType) };
    case TYPES.CHANGE_SUBJECT:
      return {
        ...state,
        subject: {
          ...state.subject,
          ...params.newSubject
        }
      };

    // Scopes
    case TYPES.CHANGE_SCOPE:
      return {
        ...state,
        scope: params.newScope
      };

    // Settings Modal
    case TYPES.OPEN_SETTINGS:
      return {
        ...state,
        showSettings: true,
        settingsRef: {
          subject: state.subject,
          scope: state.scope,
          type: state.type,
          default_value: state.default_value
        }
      };
    case TYPES.CLOSE_SETTINGS:
      return {
        ...state,
        showSettings: false,
        subject: state.settingsRef.subject,
        scope: state.settingsRef.scope,
        type: state.settingsRef.type,
        default_value: state.settingsRef.default_value,
        settingsRef: null
      };
    case TYPES.SAVE_SETTINGS:
      return { ...state, showSettings: false, settingsRef: null };
    case TYPES.RESET_SETTINGS:
      return {
        ...state,
        subject: null,
        scope: scopes[0].name,
        type: types[0].key,
        default_value: types[0].default_value
      };
    default:
      throw new Error(`No event defined in useVariableReducer for ${type}`);
  }
};

const useVariableReducer = ({
  variable = {},
  types,
  scopes,
  subjects,
  canEditScope,
  canEditType
}) => {
  const { hasPermission } = useAuth();

  const defaultVariable = {
    name: '',
    type: types[0].key,
    default_value: types[0].default_value,
    scope: scopes[0].name,
    subject: null
  };

  const initialValue = {
    ...defaultVariable,
    ...variable
  };

  const [state, originalDispatch] = useReducer(reducer, {
    name: initialValue.name,
    type: initialValue.type,
    default_value:
      initialValue.default_value === null
        ? types.find(type => type.key === initialValue.type).default_value
        : initialValue.default_value,
    scope: initialValue.scope,
    subject: setInitialSubject(initialValue.subject, subjects),
    showSettings: false,
    settingsRef: null
  });

  const dispatch = useCallback(
    (params = {}) => {
      originalDispatch({ ...params, subjects, types, scopes });
    },
    [subjects, types, scopes]
  );

  const format = useCallback(
    chosenSubject => {
      if (!chosenSubject) {
        return null;
      }

      const { toApi } = subjects.find(({ subject }) => {
        return subject.type === chosenSubject.type;
      });

      return toApi(chosenSubject);
    },
    [subjects]
  );

  const form = useMemo(() => {
    return {
      name: state.name,
      default_value: state.default_value,
      subject: format(state.subject),
      ...(canEditType && { type: state.type }),
      ...(canEditScope && { scope: state.scope })
    };
  }, [
    state.name,
    state.default_value,
    state.subject,
    state.scope,
    state.type,
    format,
    canEditType,
    canEditScope
  ]);

  const formattedSubjects = subjects.map(({ name, subject, permission }) => {
    return {
      label: name,
      value: subject.type,
      isDisabled: permission ? !hasPermission(permission) : false
    };
  });

  return {
    // State
    variable: state,
    dispatch,
    TYPES,
    // Data
    types,
    formattedSubjects,
    subjects,
    scopes,
    // Form
    form,
    // Config
    canEditScope,
    canEditType
  };
};

export default useVariableReducer;
