import React, { useEffect, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Flex, Heading1, Paragraph } from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { useAuth } from '../hooks/index';
import { switchAccounts as switchAccountsApi } from '../api/index';
import { CLIENT_ID_SESSION_KEY } from '../constants';

const DidNotSuccessfullySwitchClientRedirect = () => (
  <Redirect
    to={{
      pathname: '/home',
      state: {
        notification: {
          type: 'error',
          message: 'There was a problem switching accounts, please try again.'
        }
      }
    }}
  />
);

const SwitchAccounts = ({ match }) => {
  const { refetchAuth } = useAuth();

  const { push } = useHistory();

  const queryClient = useQueryClient();
  const { mutateAsync, isError } = useMutation(switchAccountsApi);

  const id = parseInt(match.params.id);

  const switchAccounts = useCallback(
    async id => {
      await mutateAsync(id);

      sessionStorage.setItem(CLIENT_ID_SESSION_KEY, id);

      await refetchAuth();
      await queryClient.resetQueries();

      push({
        pathname: '/home',
        state: {
          notification: {
            type: 'success',
            message: 'You have successfully switched account.'
          }
        }
      });
    },
    [mutateAsync, refetchAuth, queryClient, push]
  );

  useEffect(() => {
    switchAccounts(id);
  }, [id, switchAccounts]);

  return (
    <Flex col fat tall center middle pad>
      {isError && <DidNotSuccessfullySwitchClientRedirect />}
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Switching Account
      </Heading1>
      <Paragraph secondary textCenter>
        Please wait and your account will be switched
      </Paragraph>
    </Flex>
  );
};

export default SwitchAccounts;
