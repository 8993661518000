import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  useNotification,
  useModal
} from '@ubisend/pulse-components';

import { syncStepActions } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';
import Modal from '../../../Modal';
import ActionDetails from './ActionDetails';

const EditActionBlock = ({ handleClose, node, block }) => {
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { dispatch: builderDispatch } = useBuilder();

  const mutation = useMutation(syncStepActions, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated actions');
      builderDispatch({
        type: 'SYNC_STEP_ACTIONS',
        stepId: node.id,
        newActions: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate({
      stepId: node.id,
      actions: node.base.actions.map(action => {
        if (action.id === block.base.id) {
          return form;
        }

        return action;
      })
    });
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove action',
      body: `Are you sure you want to remove this action?`,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync({
            stepId: node.id,
            actions: node.base.actions.filter(
              action => action.id !== block.base.id
            )
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Modal
      header="Edit action"
      handleClose={handleClose}
      actions={
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}
          loading={mutation.isLoading}>
          Delete
        </Button>
      }>
      <Flex pad col>
        <ActionDetails
          handleSubmit={handleSubmit}
          loading={mutation.isLoading}
          initialAction={{ name: block.base.name, content: block.base.content }}
        />
      </Flex>
    </Modal>
  );
};

EditActionBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
};

export default EditActionBlock;
