const date = {
  id: 'date',
  name: 'Date',
  Input: null,
  setInitialContent: () => {
    return {};
  },
  formatContent: () => {
    return {};
  },
  valid: () => true,
  snippet: `{
    "type": "date",
    "content": {} 
}`
};

export default date;
