import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Pagination,
  Button,
  Tooltip,
  TableActions,
  usePaginationReducer,
  FilterMenu,
  Flex,
  useFilterReducer,
  Label,
  Select
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import SmallTalkSettings from './SmallTalkSettings';

const defaultFilters = {
  name: null
};

const SmallTalkSelect = ({ value, ...props }) => {
  const query = useQuery('smalltalk/all');

  return (
    <Select
      closeMenuOnSelect
      isClearable
      isLoading={query.isLoading}
      options={
        query.isSuccess
          ? query.data.data.map(({ name }) => ({
              value: name,
              label: name
            }))
          : []
      }
      value={value ? { value, label: value } : null}
      {...props}
    />
  );
};

SmallTalkSelect.propTypes = {
  value: PropTypes.string
};

const ManageSmallTalk = () => {
  const pagination = usePaginationReducer({ id: 'small-talk' });
  const filters = useFilterReducer({
    id: 'small-talk',
    initialFilters: defaultFilters,
    options: { pagination }
  });

  const [showSettings, setShowSettings] = useState(false);

  const query = useQuery([
    'smalltalk',
    {
      ...pagination.params,
      ...filters.form
    }
  ]);

  const handleNameSelect = option => {
    filters.updateFilters({
      name: option ? option.value : null
    });
  };

  return (
    <>
      {showSettings && (
        <SmallTalkSettings
          handleCancel={() => setShowSettings(!showSettings)}
        />
      )}
      <PageWrapper
        header="Small Talk"
        actions={
          <Flex xSpace>
            <FilterMenu
              position={FilterMenu.POSITIONS.LEFT}
              buttonProps={{
                'aria-label': 'Toggle filter menu',
                loading: query.isLoading
              }}
              {...filters.props}>
              <Flex col fat style={{ width: '20rem' }}>
                <Label htmlFor="smalltalk-names">Name</Label>
                <SmallTalkSelect
                  id="smalltalk-names"
                  value={filters.filters.name}
                  onChange={handleNameSelect}
                />
              </Flex>
            </FilterMenu>
            <PermissionFilter can="view small talk settings">
              <Button onClick={() => setShowSettings(!showSettings)} icon="cog">
                Settings
              </Button>
            </PermissionFilter>
          </Flex>
        }>
        <Panel>
          <>
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>
                    Example user trigger
                    <Tooltip
                      tooltip={
                        <Tooltip.Content>
                          An example message a user would send to trigger this
                          small talk
                        </Tooltip.Content>
                      }>
                      <Tooltip.Icon>?</Tooltip.Icon>
                    </Tooltip>
                  </TableHeadCell>
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((smallTalk, key) => (
                    <TableRow key={key}>
                      <TableCell>{smallTalk.name}</TableCell>
                      <TableCell>{smallTalk.message_example}</TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/smalltalk/${smallTalk.id}/edit`}>
                          Manage responses
                        </Button>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        </Panel>
      </PageWrapper>
    </>
  );
};

export default ManageSmallTalk;
