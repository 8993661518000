import ConfigureSitemap from './ConfigureSitemap';
import WebsiteImportEditor from './WebsiteImportEditor';
import WebsiteImportPreview from './WebsiteImportPreview';

const steps = [
  { title: 'Configure Sitemap Details', Component: ConfigureSitemap }
];

const driver = {
  wizard: {
    steps,
    success:
      "Successfully started import of Sitemap. Check back later to see your website's content in the platform."
  },
  settings: {
    initial: { url: '' },
    valid: settings => Boolean(settings.url)
  },
  content: {
    Editor: WebsiteImportEditor,
    Preview: WebsiteImportPreview
  },
  show_polling_interval: true
};

export default driver;
