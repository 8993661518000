import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  FormHeading,
  Flex,
  Label,
  FormGroup,
  Button,
  Divider,
  Span
} from '@ubisend/pulse-components';
import { useRows, Rows } from '@ubisend/pulse-hooks';

import { useAuth } from '../../hooks/index';
import { Auth } from './Auth/index';
import HeaderSelect from './HeaderSelect';

const IntegrationDetails = ({
  handleSubmit,
  initialName = '',
  initialAuth,
  initialBaseUrl = '',
  initialHeaders,
  initialShowAuth = true
}) => {
  const [name, setName] = useState(initialName);
  const auth = useAuth(initialAuth);
  const [baseUrl, setBaseUrl] = useState(initialBaseUrl);
  const headers = useRows(initialHeaders);
  const [showAuth, setShowAuth] = useState(initialShowAuth);

  const valid =
    name.length > 3 && auth.valid && baseUrl.length > 5 && headers.valid;

  const onSubmit = event => {
    event.preventDefault();

    if (!valid) {
      return false;
    }

    handleSubmit({
      name,
      base_url: baseUrl,
      headers: headers.rows,
      ...(showAuth && { authorization: auth.form })
    });
  };

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleBaseUrlChange = event => {
    setBaseUrl(event.target.value);
  };

  const handleShowAuth = () => setShowAuth(true);

  return (
    <form onSubmit={onSubmit}>
      <FormHeading>Integration Details</FormHeading>
      <FormGroup>
        <Label htmlFor="name">Integration Name</Label>
        <TextInput
          id="name"
          placeholder="Hubspot"
          value={name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="url">Base URL</Label>
        <TextInput
          id="url"
          placeholder="https://api.example.com"
          value={baseUrl}
          onChange={handleBaseUrlChange}
        />
      </FormGroup>
      <Divider />
      <Flex between>
        <FormHeading>Authorization</FormHeading>
        {!showAuth && (
          <Flex top>
            <Button onClick={handleShowAuth}>Change Credentials</Button>
          </Flex>
        )}
      </Flex>
      <FormGroup>
        {showAuth ? (
          <Auth {...auth} />
        ) : (
          <Span>Authorization credentials have already been set</Span>
        )}
      </FormGroup>
      <Divider />
      <FormHeading>Headers</FormHeading>
      <FormGroup>
        <Rows {...headers} KeyInput={HeaderSelect} />
      </FormGroup>
      <Flex right>
        <Button disabled={!valid} type="submit" icon="save">
          Save
        </Button>
      </Flex>
    </form>
  );
};

IntegrationDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialAuth: PropTypes.shape({
    type: PropTypes.string,
    credentials: PropTypes.object
  }),
  initialBaseUrl: PropTypes.string,
  initialHeaders: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
  ),
  initialShowAuth: PropTypes.bool
};

export default IntegrationDetails;
