import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const ExportAllImportsContent = ({ content, onChange }) => {
  const handleEmailChange = email => {
    onChange({ email });
  };

  return (
    <Flex xSpace fat center>
      <Span noWrap>and email to</Span>
      <Flex col fat>
        <TemplatingTextInput
          aria-label="Email"
          placeholder="Email"
          value={content.email}
          onChange={handleEmailChange}
        />
      </Flex>
    </Flex>
  );
};

ExportAllImportsContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired
};

export default ExportAllImportsContent;
