import { useState, useEffect } from 'react';

const defualtSeserialiser = value => value;

const defaultDeserialiser = value => value;

const useLocalStorage = ({
  initialValue = null,
  key,
  serialiser = defualtSeserialiser,
  deserialiser = defaultDeserialiser
}) => {
  const [state, setState] = useState(
    localStorage.getItem(key) !== null
      ? deserialiser(localStorage.getItem(key))
      : initialValue
  );

  useEffect(() => {
    localStorage.setItem(key, serialiser(state));
  }, [state, key, serialiser]);

  return [state, setState];
};

export default useLocalStorage;
